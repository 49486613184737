import React from 'react';
import TextInput from 'components/form-elements/TextInput';
import styles from './requestCredentialsModal.module.scss';

const CompanyOnlineLinks = ({ companyOnlineLinks }) => (
  <div className="row">
    <div className="col-12">
      <h3 className={styles.subHeading}>Company Online Links</h3>
    </div>
    {companyOnlineLinks &&
      companyOnlineLinks.map((link, index) => (
        <div className="col-sm-4 mb-4" key={`link${index}`}>
          <TextInput
            name={`companyOnlineLinks[${index}]`}
            placeholder="http://"
          />
        </div>
      ))}
  </div>
);

export default CompanyOnlineLinks;
