import { gql } from '@apollo/client';

export const SEND_CONTACT_SPECIALIST_EMAIL = gql`
  mutation SendContactSpecialistEmail($fields: SpecialistEmailFields!) {
    ContactSpecialistEmail(fields: $fields) {
      code
    }
  }
`;

export const SEND_REQUEST_CREDENTIALS_EMAIL = gql`
  mutation SendRequestCredentialsEmail(
    $fields: RequestCredentialsEmailFields!
  ) {
    RequestCredentialsEmail(fields: $fields) {
      code
    }
  }
`;

export const SEND_ADD_TO_MEDIA_MAILING_LIST = gql`
  mutation mediaMailingEmail($fields: MediaMailingEmailFields!) {
    mediaMailingEmail(fields: $fields) {
      code
    }
  }
`;
