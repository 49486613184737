import React from 'react';
import TextInput from 'components/form-elements/TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import styles from './requestCredentialsModal.module.scss';

const RequestPressCredentials = ({
  requestPressCredentials,
  setFieldValue,
}) => (
  <div className="row">
    <div className="col-12">
      <h3 className={styles.subHeading}>Request Press Credentials *</h3>
    </div>
    <div className="mb-4 col-12">
      <div className="row">
        {requestPressCredentials &&
          requestPressCredentials.map((req, index) => (
            <div
              className="col-sm-12 d-flex align-items-center pointer"
              key={`requestPressCredentials${index}`}
              onClick={() => {
                let value = [...requestPressCredentials];
                value[index].selected = !value[index].selected;
                setFieldValue('requestPressCredentials', value);
              }}
              role="presentation"
            >
              <FontAwesomeIcon
                icon={req.selected ? faCheckSquare : faSquare}
                color={req.selected ? '#1c5bae' : '#b0b0b0'}
              />
              <p className="mt-0 mb-0 ml-3">{req.label}</p>
            </div>
          ))}
      </div>
    </div>
    <div className="col-sm-4 mb-4">
      <TextInput
        name="assignment"
        placeholder="Assignment*"
        showErrorMessages={false}
      />
    </div>
    <div className="col-sm-4 mb-4">
      <TextInput
        name="editor"
        placeholder="Editor*"
        showErrorMessages={false}
      />
    </div>
    <div className="col-sm-4 mb-4">
      <TextInput
        name="editorEmail"
        placeholder="Editor Email*"
        showErrorMessages={false}
      />
    </div>
  </div>
);

export default RequestPressCredentials;
