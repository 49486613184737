import React from 'react';
import PhoneNumber from 'components/form-elements/PhoneNumber';
import styles from './requestCredentialsModal.module.scss';

const PhoneNumberInformation = () => (
  <div className="row">
    <div className="col-12">
      <h3 className={styles.subHeading}>Phone Number</h3>
    </div>
    <div className="col-sm-4 mb-4">
      <PhoneNumber
        name="phoneNumber"
        placeholder="Phone Number*"
        showErrorMessages={false}
        label="&#42;"
      />
    </div>
    <div className="col-sm-4 mb-4">
      <PhoneNumber name="phoneNumber1" placeholder="Phone Number*" />
    </div>
  </div>
);

export default PhoneNumberInformation;
