import * as Yup from 'yup';

const INITIAL_VALUES = {
  attendAuctionsInPast: false,
  firstName: '',
  lastName: '',
  jobTitle: '',
  email: '',
  phoneNumber: '',
  phoneNumber1: '',
  firstAddress: '',
  secondAddress: '',
  city: '',
  state: '',
  country: 'United States',
  zipcode: '',
  companyOnlineLinks: ['', '', ''],
  requestPressCredentials: [
    {
      label: 'The Pebble Beach Auctions - Postponed Until 2021',
      selected: true,
    },
    {
      label: 'The Scottsdale Auctions - January 22 & 23, 2021',
      selected: false,
    },
    {
      label: 'Passion of a Lifetime Auction - Postponed',
      selected: false,
    },
  ],
  assignment: '',
  editor: '',
  editorEmail: '',
  additionalComments: '',
};

const VALIDATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string().required('Please enter First Name'),
  lastName: Yup.string().required('Please enter Last Name'),
  jobTitle: Yup.string().required('Please enter Job Title'),
  email: Yup.string()
    .email('Please enter a valid email.')
    .required('Email is required.'),
  phoneNumber: Yup.string().required('Phone is required').nullable(),
  firstAddress: Yup.string().required('Please enter Assress 1'),
  city: Yup.string().required('City is required.'),
  state: Yup.string().required('State is required.'),
  country: Yup.string().required('Country is required.'),
  zipcode: Yup.string().required('Zipcode is required.'),
  assignment: Yup.string().required('Please enter Assignment'),
  editor: Yup.string().required('Please enter Editor'),
  editorEmail: Yup.string()
    .email('Please enter a valid email.')
    .required('Email is required.'),
  requestPressCredentials: Yup.array().test(
    'auctionTest',
    'Please select at least one',
    function (values) {
      const selected = values.filter(value => value.selected === true);
      return selected.length > 0;
    }
  ),
});

export { INITIAL_VALUES, VALIDATION_SCHEMA };
