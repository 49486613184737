import React from 'react';
import TextArea from 'components/form-elements/TextArea';
import styles from './requestCredentialsModal.module.scss';

const AdditionalQuestions = () => (
  <div>
    <div className="row">
      <div className="col-sm-12">
        <h3 className={styles.subHeading}>Additional Comments</h3>
      </div>
      <div className="col-sm-8 mb-2">
        <TextArea
          name="additionalComments"
          placeholder="Additional Comments or Questions"
        />
      </div>
    </div>
  </div>
);

export default AdditionalQuestions;
