import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faDotCircle } from '@fortawesome/free-regular-svg-icons';

const AttendAuction = ({ attendAuctionsInPast, setFieldValue }) => (
  <div className="d-flex flex-wrap mt-3 mb-4">
    <p className="mb-3 mt-0 mr-sm-3">Have you attended auctions in the past?</p>
    <div>
      <span
        className="mr-3 d-inline-flex align-items-center pointer"
        onClick={() =>
          _.isFunction(setFieldValue) &&
          setFieldValue('attendAuctionsInPast', true)
        }
        role="presentation"
      >
        <FontAwesomeIcon
          icon={attendAuctionsInPast ? faDotCircle : faCircle}
          className="mr-3"
          color={attendAuctionsInPast ? '#1c5bae' : '#b0b0b0'}
        />
        Yes
      </span>
      <span
        className="d-inline-flex align-items-center pointer"
        onClick={() =>
          _.isFunction(setFieldValue) &&
          setFieldValue('attendAuctionsInPast', false)
        }
        role="presentation"
      >
        <FontAwesomeIcon
          icon={!attendAuctionsInPast ? faDotCircle : faCircle}
          className="mr-3"
          color={!attendAuctionsInPast ? '#1c5bae' : '#b0b0b0'}
        />
        No
      </span>
    </div>
  </div>
);

AttendAuction.propTypes = {
  attendAuctionsInPast: PropTypes.bool,
  setFieldValue: PropTypes.func,
};

export default AttendAuction;
